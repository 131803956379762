<template>
  <div>
    <label>{{ label }}</label>
    <b-list-group>
      <b-list-group-item class="d-flex justify-content-between" v-for="(option, index) of ops"
                         :class="{'selected': option.selected}" :title="option.description">
        <div @click="changeSelected(index, option)" style="width: 100%">
          {{ option.name }}
        </div>
        <b-checkbox v-model="option.required" v-if="option.selected && withCheck" @change="emit"/>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
export default {
  name: "SelectX",
  props: {
    value: {
      type: Array,
      required: true,
      default: []
    },
    label: {
      type: String,
      required: true,
      default: ''
    },
    options: {
      type: Array,
      required: true,
      default: []
    },
    withCheck: {default: false, type: Boolean, required: false}
  },
  data: () => ({
    ops: []
  }),
  methods: {
    onMount() {
      this.ops = this.options.map(option => {
        this.value = !!this.value ? this.value : [];
        const valueSelect = this.value.find(el => el == option.optionId || el == `${option.optionId}x`)
        return {
          ...option,
          selected: valueSelect !== undefined,
          required: valueSelect ? valueSelect.includes('x') : false
        }
      });
    },
    emit() {
      this.$emit('input', this.ops.map(option => option.selected ? `${option.optionId}${option.required ? 'x' : ''}` : null).filter(el => el))
    },
    changeSelected(index, item) {
      item.selected = !item.selected;
      if (!item.selected)
        item.required = false;
      this.emit();
    }
  },
  watch: {
    options: function () {
      this.onMount();
    }
  }
}
</script>

<style scoped>
.list-group-item {
  padding: 5px 15px;
  cursor: pointer;
}

.selected {
  background: #efefef;
}
</style>
