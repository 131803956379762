<template>
  <vue-autosuggest
    :suggestions="[{data: suggestions}]"
    v-model="inputValue"
    :input-props="{placeholder: field, class: classList, value: inputValue}"
    @input="onInputChange"
    @selected="selectHandler"
  >
  </vue-autosuggest>
</template>

<script>
import {VueAutosuggest} from 'vue-autosuggest';
import {get} from '@/services/api';

export default {
  name: 'SelectSuggest',
  components: {
    VueAutosuggest
  },
  props: {
    value: {
      type: String,
      required: true,
      default: ''
    },
    field: {
      type: String,
      required: true,
      default: 'make'
    },
    isDsg: {default: false, type: Boolean, required: false},
    state: {default: null}
  },
  data: function () {
    return {
      suggestionsList: [],
      originalData: [],
      isReFiltered: false
    };
  },
  computed: {
    suggestions: function () {
      if (this.isReFiltered)
        return this.suggestionsList.filter(el => {
          return el.toLowerCase()
            .includes(this.inputValue.toLowerCase());
        });
      else
        return [...new Set(this.suggestionsList)].filter(el => {
          return el.toLowerCase()
            .includes(this.inputValue.toLowerCase());
        })
    },
    classList: function () {
      return `form-control ${typeof this.state == 'boolean' ? this.state ? 'is-valid' : 'is-invalid' : ''}`;
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    loadField() {
      if (this.isDsg) {
        get(`vehicles-dsg/`, null, true)
          .then(({data}) => {
            this.suggestionsList = data.message.map(el => el.DSG);
          });
      } else {
        get(`vehicles/get-group/${this.field}`, null, true)
          .then(({data}) => {
            this.originalData = data;
            this.suggestionsList = data.map(el => el[this.field]);
          });
      }
    },
    selectHandler(e) {
      if (e) {
        this.$emit('input', e.item);
      }
    },
    reFilter(value, field) {
      this.isReFiltered = true;
      this.suggestionsList = (this.originalData.filter(el => {
        return el[field] === value
      })).map(el => el[this.field]);
    }
  },
  mounted() {
    this.loadField();
    if (this.suggestionsList.includes(this.value)) {
      this.$emit('input', this.value)
    }
  },
  watch: {
    value() {
      this.$emit('input', this.value);
    }
  }
};
</script>

<style lang="scss">
.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 100;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  padding: 0;
  overflow-y: scroll;
  max-height: 200px;

  .autosuggest__results-item {
    cursor: pointer;
    padding: 5px;

    &:hover, &:active, &:focus {
      background: #e3e3e3;
    }
  }
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
</style>
