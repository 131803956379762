<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }" style="width: 100%" v-if="vehicle">
    <form style="width: 100%" @submit.prevent="handleSubmit(save)">
      <b-form-row>
        <b-col class="form-group" md="4">
          <label>Make</label>
          <ValidationProvider name="make" rules="required">
            <div slot-scope="{ errors, valid, validated }">
              <SelectSuggest v-model="vehicle.make" field="make" v-bind:state="validated ? valid:null"
                             @input="$refs.model.reFilter($event, 'make')"/>
              <span v-if="oldValue">Original. {{ oldValue.make }}</span>
            </div>
          </ValidationProvider>
        </b-col>
        <b-col class="form-group" md="4">
          <label>Model</label>
          <ValidationProvider name="model" rules="required">
            <div slot-scope="{ errors, valid, validated }">
              <SelectSuggest class="toCapitalFirst" v-model="vehicle.model" field="model"
                             v-bind:state="validated ? valid:null" ref="model"
                             @input="$refs.generation.reFilter($event, 'model')"/>
              <span v-if="oldValue">Original. {{ oldValue.model }}</span>
            </div>
          </ValidationProvider>
        </b-col>
        <b-col class="form-group" md="4">
          <label>Generation</label>
          <ValidationProvider name="generation" rules="required">
            <div slot-scope="{ errors, valid, validated }">
              <SelectSuggest v-model="vehicle.generation" field="generation" v-bind:state="validated ? valid:null"
                             ref="generation"
                             @input="$refs.engine.reFilter($event, 'generation')"/>
              <span v-if="oldValue">Original. {{ oldValue.generation }}</span>
            </div>
          </ValidationProvider>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col class="form-group" md="4">
          <label>Engine</label>
          <ValidationProvider name="engine" rules="required">
            <div slot-scope="{ errors,validated, valid }">
              <SelectSuggest v-model="vehicle.engine" field="engine" v-bind:state="validated ? valid:null"
                             ref="engine"/>
              <span v-if="oldValue">Original. {{ oldValue.engine }}</span>
            </div>
          </ValidationProvider>
        </b-col>
        <b-col class="form-group" md="4">
          <label>Engine Code</label>
          <ValidationProvider name="engineCode" rules="required">
            <div slot-scope="{ errors, validated, valid }">
              <b-form-tags v-model="vehicle.engineCode" placeholder="Engine Code"
                           type="text" :state="validated ? valid:null"/>
              <span v-if="oldValue">Original. {{ oldValue.engineCode.join(', ') }}</span>
            </div>
          </ValidationProvider>
        </b-col>
        <b-col class="form-group" md="4">
          <label>DSG</label>
          <ValidationProvider name="dsg" rules="required">
            <div slot-scope="{ errors, validated, valid }">
              <SelectSuggest v-model="vehicle.dsg" :is-dsg="true" field="DSG" v-bind:state="validated ? valid:null"/>
              <span v-if="oldValue">Original. {{ oldValue.dsg }}</span>
            </div>
          </ValidationProvider>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col class="form-group" md="4">
          <label>Stock Power</label>
          <ValidationProvider name="stock_power" rules="required">
            <div slot-scope="{ errors, validated, valid }">
              <b-form-input v-model="vehicle.stockPower" placeholder="Stock Power"
                            type="text" :state="validated ? valid:null"/>
            </div>
          </ValidationProvider>
        </b-col>
        <b-col class="form-group" md="4">
          <label>Stock Torque</label>
          <ValidationProvider name="stock_torque" rules="required">
            <div slot-scope="{ errors, validated, valid }">
              <b-form-input v-model="vehicle.stockTorque" placeholder="Stock Torque"
                            type="text" :state="validated ? valid:null"/>
            </div>
          </ValidationProvider>
        </b-col>
      </b-form-row>
      <b-form-row class="mb-4">
        <b-col title="Show in creation order.">
          <label>Status</label>
          <b-form-checkbox switch size="lg" v-model="vehicle.status">{{ vehicle.status ? 'Active' : 'Inactive' }}
          </b-form-checkbox>
        </b-col>
        <b-col v-if="id !== null && showReview">
          <label>Review finished? ?</label>
          <b-form-checkbox switch size="lg"
                           v-model="vehicle.toReview">{{ vehicle.toReview ? 'Yes' : 'No' }}
          </b-form-checkbox>
        </b-col>
      </b-form-row>
      <b-tabs justified fill>
        <b-tab title="STAGE ONE" class="pt-3">
          <b-form-row>
            <b-col class="form-group" md="4">
              <label>Stage One Power</label>
              <b-form-input v-model="vehicle.stageOnePower"
                            placeholder="Stage One Power"
                            type="text"/>
            </b-col>
            <b-col class="form-group" md="4">
              <label>Stage One Torque</label>
              <b-form-input v-model="vehicle.stageOneTorque"
                            placeholder="Stage One Torque"
                            type="text"/>
            </b-col>
            <b-col class="form-group" md="4">
              <label>Stage One Price</label>
              <b-form-input v-model="vehicle.stageOnePrice"
                            placeholder="Stage One Price"
                            type="text"/>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col class="form-group" md="12">
              <select-x v-model="vehicle.stageOneOne" :options="getOptions(optionsType.stageOneOne)"
                        :label="optionsType.stageOneOne "/>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col class="form-group" md="12">
              <select-x v-model="vehicle.stageOneTwo" :options="getOptions(optionsType.stageOneTwo)"
                        :label="optionsType.stageOneTwo" with-check/>
            </b-col>
          </b-form-row>
        </b-tab>
        <b-tab title="STAGE TWO" class="pt-3">
          <b-form-row>
            <b-col class="form-group" md="4">
              <label>Stage Two Power</label>
              <b-form-input v-model="vehicle.stageTwoPower"
                            placeholder="Stage Two Power" type="text"/>
            </b-col>
            <b-col class="form-group" md="4">
              <label>Stage Two Torque</label>
              <b-form-input v-model="vehicle.stageTwoTorque"
                            placeholder="Stage Two Torque"
                            type="text"/>
            </b-col>
            <b-col class="form-group" md="4">
              <label> Price</label>
              <b-form-input v-model="vehicle.stageTwoPrice"
                            placeholder="Stage Two Price"
                            type="text"/>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col class="form-group" md="12">
              <select-x v-model="vehicle.stageTwoOne" :options="getOptions(optionsType.stageTwoOne)"
                        :label="optionsType.stageTwoOne "/>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col class="form-group" md="12">
              <select-x v-model="vehicle.stageTwoTwo" :options="getOptions(optionsType.stageTwoTwo)"
                        :label="optionsType.stageTwoTwo " with-check/>
            </b-col>
            <b-col class="form-group" md="12">
              <select-x v-model="vehicle.stageTwoThree" :options="getOptions(optionsType.stageTwoThree)"
                        :label="optionsType.stageTwoThree " with-check/>
            </b-col>
          </b-form-row>
        </b-tab>
        <b-tab title="STAGE TWO PLUS" class="pt-3">
          <b-form-row>
            <b-col class="form-group" md="4">
              <label>Stage Two Plus Power</label>
              <b-form-input v-model="vehicle.stageTwoPlusPower"
                            placeholder="Stage Two Plus Power" type="text"/>

            </b-col>
            <b-col class="form-group" md="4">
              <label>Stage Two Plus Torque</label>
              <b-form-input v-model="vehicle.stageTwoPlusTorque"
                            placeholder="Stage Two Plus Torque"
                            type="text"/>
            </b-col>
            <b-col class="form-group" md="4">
              <label>Stage Two Plus Price</label>
              <b-form-input v-model="vehicle.stageTwoPlusPrice"
                            placeholder="Stage Two Plus Price" type="text"/>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col class="form-group" md="12">
              <select-x v-model="vehicle.stageTwoPlusOne" :options="getOptions(optionsType.stageTwoPlusOne)"
                        :label="optionsType.stageTwoPlusOne "/>

            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col class="form-group" md="12">
              <select-x v-model="vehicle.stageTwoPlusTwo" :options="getOptions(optionsType.stageTwoPlusTwo)"
                        :label="optionsType.stageTwoPlusTwo" with-check/>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col class="form-group" md="12">
              <select-x v-model="vehicle.stageTwoPlusThree" :options="getOptions(optionsType.stageTwoPlusThree)"
                        :label="optionsType.stageTwoPlusThree" with-check/>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col class="form-group" md="12">
              <select-x v-model="vehicle.stageTwoPlusFour" :options="getOptions(optionsType.stageTwoPlusFour)"
                        :label="optionsType.stageTwoPlusFour"/>
            </b-col>
          </b-form-row>
        </b-tab>
        <b-tab title="STAGE THREE" class="pt-3">
          <b-form-row>
            <b-col class="form-group" md="4">
              <label>Stage Three Power</label>
              <b-form-input v-model="vehicle.stageThreePower"
                            placeholder="Stage Three Power"
                            type="text"/>
            </b-col>
            <b-col class="form-group" md="4">
              <label>Stage Three Torque</label>
              <b-form-input v-model="vehicle.stageThreeTorque"
                            placeholder="Stage Three Torque"
                            type="text"/>
            </b-col>

            <b-col class="form-group" md="4">
              <label>Stage Three Price</label>
              <b-form-input v-model="vehicle.stageThreePrice"
                            placeholder="Stage Three Price"
                            type="text"/>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col class="form-group" md="12">
              <select-x v-model="vehicle.stageThreeOne" :options="getOptions(optionsType.stageThreeOne)"
                        :label="optionsType.stageThreeOne"/>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col class="form-group" md="12">
              <select-x v-model="vehicle.stageThreeTwo" :options="getOptions(optionsType.stageThreeTwo)"
                        :label="optionsType.stageThreeTwo" with-check/>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col class="form-group" md="12">
              <select-x v-model="vehicle.stageThreeThree" :options="getOptions(optionsType.stageThreeThree)"
                        :label="optionsType.stageThreeThree" with-check/>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col class="form-group" md="12">
              <select-x v-model="vehicle.stageThreeFour" :options="getOptions(optionsType.stageThreeFour)"
                        :label="optionsType.stageThreeFour"/>
            </b-col>
          </b-form-row>
        </b-tab>
        <b-tab title="STAGE FOUR" class="pt-3">
          <b-form-row>
            <b-col class="form-group" md="4">
              <label>Stage Four Power</label>
              <b-form-input v-model="vehicle.stageFourPower"
                            placeholder="Stage Four Power"
                            type="text"/>
            </b-col>

            <b-col class="form-group" md="4">
              <label>Stage Four Torque</label>
              <b-form-input v-model="vehicle.stageFourTorque"
                            placeholder="Stage Four Torque" type="text"/>
            </b-col>
            <b-col class="form-group" md="4">
              <label>Stage Four Price</label>
              <b-form-input v-model="vehicle.stageFourPrice"
                            placeholder="Stage Four Price"
                            type="text"/>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col class="form-group" md="12">
              <select-x v-model="vehicle.stageFourOne" :options="getOptions(optionsType.stageFourOne)"
                        :label="optionsType.stageFourOne"/>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col class="form-group" md="12">
              <select-x v-model="vehicle.stageFourTwo" :options="getOptions(optionsType.stageFourTwo)"
                        :label="optionsType.stageFourTwo" with-check/>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col class="form-group" md="12">
              <select-x v-model="vehicle.stageFourThree" :options="getOptions(optionsType.stageFourThree)"
                        :label="optionsType.stageFourThree" with-check/>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col class="form-group" md="12">
              <select-x v-model="vehicle.stageFourFour" :options="getOptions(optionsType.stageFourFour)"
                        :label="optionsType.stageFourFour"/>
            </b-col>
          </b-form-row>
        </b-tab>
      </b-tabs>
      <div class="mt-3 text-right">
        <b-button variant="primary" type="submit">Save</b-button>
      </div>
    </form>
  </ValidationObserver>
</template>Create a new Car

<script>
import {get, post} from '@/services/api';
import SelectX from '@/components/vehicles/SelectX.vue';
import SelectSuggest from '@/components/common/SelectSuggest.vue';

export default {
  name: 'ViewVehicle',
  components: {
    SelectSuggest,
    SelectX
  },
  data: () => ({
    id: null,
    vehicle: {
      make: '',
      model: '',
      generation: '',
      engine: '',
      engineCode: [],
      engineFamily: '',
      dsg: '',
      stockPower: '',
      stockTorque: '',
      stageOnePower: '',
      stageOneTorque: '',
      stageOneOne: '',
      stageOnePrice: '',
      stageTwoPower: '',
      stageTwoTorque: '',
      stageTwoOne: '',
      stageTwoPrice: '',
      stageTwoPlusPower: '',
      stageTwoPlusTorque: '',
      stageTwoPlusOne: '',
      stageTwoPlusTwo: '',
      stageTwoPlusThree: '',
      stageTwoPlusFour: '',
      stageTwoPlusPrice: '',
      stageThreePower: '',
      stageThreeTorque: '',
      stageThreeOne: '',
      stageThreeTwo: '',
      stageThreeThree: '',
      stageThreeFour: '',
      stageThreePrice: '',
      stageFourPower: '',
      stageFourTorque: '',
      stageFourOne: '',
      stageFourTwo: '',
      stageFourThree: '',
      stageFourFour: '',
      stageFourPrice: '',
      status: 0,
      toReview: 1
    },
    relation: {
      stageOneOne: [],
      stageTwoOne: [],
      stageTwoPlusOne: [],
      stageTwoPlusTwo: [],
      stageTwoPlusThree: []
    },
    options: [],
    optionsType: {
      stageOneOne: 'location',
      stageOneTwo: 'engine_software',
      stageTwoOne: 'location',
      stageTwoTwo: 'engine_software',
      stageTwoThree: 'hardware_modification',
      stageTwoPlusOne: 'location',
      stageTwoPlusTwo: 'engine_software',
      stageTwoPlusThree: 'hardware_modification',
      stageTwoPlusFour: 'warning',
      stageThreeOne: 'location',
      stageThreeTwo: 'engine_software',
      stageThreeThree: 'hardware_modification',
      stageThreeFour: 'warning',
      stageFourOne: 'location',
      stageFourTwo: 'engine_software',
      stageFourThree: 'hardware_modification',
      stageFourFour: 'warning',
    },
    vehicleDataFromCopy: null,
    oldValue: null,
    showReview: false
  }),
  mounted() {
    this.loadOptions();
  },
  methods: {
    loadVehicle(id) {
      this.id = id;
      get(`vehicles/${this.id}`, null, true)
        .then(({data}) => {
          this.vehicle = data;
          this.vehicle.status = data.status === 1;
          this.showReview = this.vehicle.toReview === 1;
          this.vehicle.toReview = data.toReview !== 1;
          this.vehicle.engineCode = JSON.parse(data.engineCode);
          if (this.vehicleDataFromCopy) {
            this.vehicleDataFromCopy.make = this.vehicleDataFromCopy.make.charAt(0).toUpperCase() + this.vehicleDataFromCopy.make.slice(1)
            this.vehicleDataFromCopy.model = this.vehicleDataFromCopy.model.charAt(0).toUpperCase() + this.vehicleDataFromCopy.model.slice(1)
            this.oldValue = JSON.parse(JSON.stringify(this.vehicle));
            Object.assign(this.vehicle, this.vehicleDataFromCopy);
          }
        });
    },
    loadOptions() {
      get('vehicles-options', null, true)
        .then(({data}) => {
          this.options = data;
        });
    },
    getOptions(type) {
      return this.options.filter(el => el.type === type);
    },
    setToCopy(model) {
      this.vehicleDataFromCopy = model;
    },
    save() {
      let endPoint = `vehicles/update/${this.id}`;
      let postData = JSON.parse(JSON.stringify(this.vehicle));
      if (this.vehicleDataFromCopy || !this.id) {
        endPoint = 'vehicles/create';
        delete postData.id;
      }
      Object.keys(this.optionsType)
        .forEach(key => {
          postData[key] = postData[key] ? postData[key].join(';') : null;
        });
      postData.engineCode = JSON.stringify(postData.engineCode);
      postData.toReview = !postData.toReview;
      this.$store.commit('loaderManager', false);
      post(endPoint, postData, true)
        .then(({data}) => {
          this.$emit('created', data);
          this.$swal({
            title: 'Success',
            text: '',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#d14343',
            cancelButtonColor: '#000000',
          });
        })
        .finally(() => this.$store.commit('loaderManager', false));
    },
    clear() {
      this.vehicle = {
        make: '',
        model: '',
        generation: '',
        engine: '',
        engineCode: [],
        engineFamily: '',
        dsg: '',
        stockPower: '',
        stockTorque: '',
        stageOnePower: '',
        stageOneTorque: '',
        stageOneOne: '',
        stageOnePrice: '',
        stageTwoPower: '',
        stageTwoTorque: '',
        stageTwoOne: '',
        stageTwoPrice: '',
        stageTwoPlusPower: '',
        stageTwoPlusTorque: '',
        stageTwoPlusOne: '',
        stageTwoPlusTwo: '',
        stageTwoPlusThree: '',
        stageTwoPlusFour: '',
        stageTwoPlusPrice: '',
        stageThreePower: '',
        stageThreeTorque: '',
        stageThreeOne: '',
        stageThreeTwo: '',
        stageThreeThree: '',
        stageThreeFour: '',
        stageThreePrice: '',
        stageFourPower: '',
        stageFourTorque: '',
        stageFourOne: '',
        stageFourTwo: '',
        stageFourThree: '',
        stageFourFour: '',
        stageFourPrice: '',
        status: 0,
        toReview: 1
      };
    }
  },
};
</script>

<style lang="scss">
.badge.b-form-tag {
  padding: .3em;

  & + .b-form-tag {
    margin-left: .3em;
  }
}

.b-form-tag-content {
  font-size: 1.3em;
}

.b-form-tag-remove {
  padding-left: .3em !important;
  font-size: 1.5em;
  color: #fff !important;
}

.toCapitalFirst {
  text-transform: capitalize;
}
</style>
